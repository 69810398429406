#page-index {
	.scaled-text {
		margin-bottom: 40px;

		.raw-text {
			// Quick SEO attempt
			position: absolute;
			opacity: 0.00001;
			pointer-events: none;

			// display: block;
			// font-size: 3vh;
			// line-height: 3.6vh;
			font-size: 14px;
			line-height: 18px;

			p {
				margin: 0 !important;
			}

			// @media screen and (max-height: 800px), print {
			// 	font-size: 27px;
			// 	line-height: 32px;
			// 	letter-spacing: 0;
			// 	margin-bottom: 20px;

			// 	@media screen and (max-width: 1100px), print {
			// 		font-size: 24px;
			// 		line-height: 32px;
			// 		letter-spacing: -0.5px;
			// 	}
			// }

			// @media screen and (max-height: 700px), print {
			// 	font-size: 24px;
			// 	line-height: 32px;
			// 	letter-spacing: 0;
			// 	margin-bottom: 0;
			// }

			// p {
			// 	margin-top: 0;

			// 	@media screen and (max-height: 800px), print {
			// 		margin-bottom: 10px;

			// 		@media screen and (max-width: 1100px), print {
			// 			margin-bottom: 5px;
			// 		}
			// 	}
			// }
		}

		.expanded-text {
			// display: none;
			width: 100%;
		}

		// @media screen and (min-width: 800px), print {
		// 	.raw-text {
		// 		display: none;
		// 	}

		// 	.expanded-text {
		// 		display: block;
		// 	}
		// }
	}

	#glide > .flex {
		display: flex;
		align-items: center;

		h3 {
			margin-right: 40px;

			@media screen and (max-height: 800px), print {
				margin-top: 20px;
				margin-bottom: 10px;

				@media screen and (max-width: 1100px), print {
					margin-top: 15px;
					margin-bottom: 5px;
				}
			}
		}
	}

	.glide__bullet {
		appearance: none;
		border-radius: 50%;
		border: 2px solid $black;
		background-color: $white;
		width: 14px;
		height: 14px;
		display: inline-block;
		padding: 0;
		cursor: pointer;
		margin-right: 6px;

		&--active {
			background-color: $black;
		}
	}

	.article {
		time {
			font-weight: 500;
			color: $black;
		}

		div {
			font-size: 24px;
			line-height: 32px;
			letter-spacing: 0;

			@media screen and (max-height: 800px), print {
				font-size: 18px;
				line-height: 26px;

				@media screen and (max-width: 1100px), print {
					font-size: 18px;
					line-height: 24px;
				}
			}
		}
	}
}

#page-rd {
	.dynamic-text {
		max-width: 800px;

		h3 {
			margin-top: 0;
			&:not(:first-of-type) {
				margin-top: 2em;
				margin-bottom: 0em;
			}
		}
	}
}

#page-about {
	.scaled-text {
		// margin-bottom: 40px;

		.raw-text {
			// Quick SEO attempt
			position: absolute;
			opacity: 0.00001;
			pointer-events: none;

			// display: block;
			// font-size: 3vh;
			// line-height: 3.6vh;
			font-size: 14px;
			line-height: 18px;

			p {
				margin: 0 !important;
			}

			// @media screen and (max-height: 800px), print {
			// 	font-size: 27px;
			// 	line-height: 32px;
			// 	letter-spacing: 0;
			// 	margin-bottom: 20px;

			// 	@media screen and (max-width: 1100px), print {
			// 		font-size: 24px;
			// 		line-height: 32px;
			// 		letter-spacing: -0.5px;
			// 	}
			// }

			// @media screen and (max-height: 700px), print {
			// 	font-size: 24px;
			// 	line-height: 32px;
			// 	letter-spacing: 0;
			// 	margin-bottom: 0;
			// }

			// p {
			// 	margin-top: 0;

			// 	@media screen and (max-height: 800px), print {
			// 		margin-bottom: 10px;

			// 		@media screen and (max-width: 1100px), print {
			// 			margin-bottom: 5px;
			// 		}
			// 	}
			// }
		}

		.expanded-text {
			// display: none;
			width: 100%;
		}
	}
}

#page-legal {
	.legal-section {
		margin-bottom: 60px;

		h2 {
			margin-top: 0;
			@include type-size-3;
		}

		@include desktop {
			display: grid;
			grid-template-columns: 3fr 6fr;
			grid-gap: 20px;

			h2,
			p {
				margin-top: 0;
			}
		}
	}
}

#page-locations {
	main {
		.shadow {
			display: none;
		}

		#size-container {
			@include type-size-3;

			@include tablet {
				height: 100%;
				display: flex;
				flex-direction: column;
			}

			@include desktop {
				display: grid;
				grid-template-columns: 1fr 1fr;
			}
		}
	}

	.location {
		display: none;
		margin-bottom: 20px;
		padding-right: 20px;

		p {
			margin: 0;
		}

		&:target {
			display: block;
		}
	}

	#map {
		// transition: opacity 150ms ease-in-out;
		width: 100%;
		height: calc(100vw - 40px);

		&.hidden {
			opacity: 0;
			display: none;
		}

		@include tablet {
			height: 500px;
		}
	}
}

#page-contact {
	main {
		p:first-of-type {
			margin-top: 0;
		}

		p {
			margin-top: 60px;
		}

		#size-container {
			@include type-size-3;

			@include tablet {
				height: 100%;
				display: flex;
				flex-direction: column;
			}

			@include desktop {
				display: grid;
				grid-template-columns: 1fr 1fr;
			}
		}
	}
}

#page-team {
	.role {
		margin-bottom: 30px;

		&.hidden {
			display: none;
		}
	}

	#size-container {
		@include desktop {
			display: grid;
			grid-template-columns: 1fr 2fr;
			grid-gap: 20px;
		}
	}

	#column-left {
		margin-bottom: 40px;

		h2 {
			@include type-size-3;
			margin-top: 40px;
			margin-bottom: 5px;

			&:first-of-type {
				margin-top: 0;
			}
		}

		a {
			@include type-size-3;
			display: block;
			margin-bottom: 5px;
		}

		@include desktop {
			margin-bottom: 0;
		}
	}

	.person {
		display: none;

		& :first-child {
			margin-top: 0;
		}

		&:target {
			display: block;
		}
	}

	.responsibilities {
		margin-top: 30px;
		margin-bottom: 40px;

		h3 {
			@include type-size-4;
			margin-bottom: 0;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}

		@include tablet {
			display: grid;
			grid-template-columns: 1fr 1fr;
			margin-bottom: 0;
		}
	}
}

#page-portfolio {
	#size-container {
		@include desktop {
			display: grid;
			grid-template-columns: 1fr 2fr;
			grid-gap: 20px;
		}
	}

	#column-left {
		margin-bottom: 40px;

		h2 {
			@include type-size-3;
			margin-top: 40px;
			margin-bottom: 5px;

			&:first-of-type {
				margin-top: 0;
			}
		}

		a {
			display: block;
			@include type-size-3;

			&.active {
				color: $red;
			}
		}

		.hidden {
			display: none;
		}

		@include desktop {
			margin-bottom: 0;
		}
	}

	.item {
		display: none;

		&:target {
			display: block;
		}

		h2 {
			margin-top: 0;
		}

		.logo {
			img {
				display: block;
				width: 100%;
				height: 100%;
				max-width: 400px;
				max-height: 220px;
				margin-bottom: 40px;
				object-fit: contain;
				object-position: left center;

				&.smaller {
					max-width: 340px;
					max-height: 160px;
				}

				&.larger {
					max-width: 440px;
					max-height: 240px;
				}
			}
		}

		.related-news {
			margin-top: 40px;
			margin-bottom: 40px;

			h3 {
				@include type-size-4;

				margin: 0;
			}

			ul {
				margin: 0;
				padding: 0;
				list-style-type: none;
			}

			li {
				margin-bottom: 20px;
			}

			time {
				display: block;
			}

			@include tablet {
				display: grid;
				grid-template-columns: 1fr 1fr;
				margin-bottom: 0;
			}
		}
	}
}

#page-news {
	.hidden {
		display: none;
	}

	.article {
		margin-bottom: 40px;

		time {
			font-weight: 500;
			color: $black;
			display: block;
		}

		a {
			@include type-size-3;
		}
	}
}

#page-news-detail {
	#back-arrow {
		display: none;

		path,
		polygon {
			transition: fill 150ms ease-in-out;
		}

		&:hover path,
		&:hover polygon {
			fill: $red;
		}
	}

	#share {
		> div {
			display: flex;
			align-items: center;
		}

		[href*="facebook"] {
			position: relative;
			top: -1px;
		}

		[href*="linkedin"] {
			position: relative;
			top: -2px;
		}

		[href*="mail"] {
			position: relative;
			top: 1px;
		}

		svg {
			height: 18px;
			width: 18px;
			margin-right: 12px;
			fill: currentColor;
		}
	}

	@include desktop {
		#back-arrow {
			display: block;
		}

		#size-container {
			display: grid;
			grid-gap: 20px;
			grid-template-columns: 1fr 2fr;
		}

		#share {
			position: sticky;
			top: -20px;
			height: max-content;
		}
	}
}
