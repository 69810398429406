#logo {
	margin-bottom: 20px;
	width: 70%;

	a {
		position: relative;
		display: grid;
		color: $red;
		grid-template-columns: 1.5fr 1fr auto;

		@include type-size-1;

		@include tablet {
			font-size: 70px;
		}
	}

	// These seemingly random numbers are to ensure the P in ATP aligns with the content
	@include tablet {
		width: 100%;
		a {
			width: 212px;
		}
	}

	@include desktop {
		a {
			width: 308px;
		}
	}
}

#navigation {
	border-top: 1px solid $light-gray;
	border-bottom: 1px solid $light-gray;
	padding: 20px 0;
	margin-bottom: 20px;

	a {
		@include type-size-3;
	}

	@include tablet {
		border-top: none;
		border-bottom: none;
		padding: 0;
		margin: 0;

		margin-bottom: 40px;
	}
}

#subnavigation {
	border-bottom: 1px solid $light-gray;

	padding-bottom: 20px;
	margin-bottom: 20px;

	a {
		@include type-size-3;
	}

	button {
		appearance: none;
		padding: 0;
		margin: 0;
		width: 100%;
		font-size: inherit;
		border: 0;
		background-color: transparent;
		border-radius: 0;
		color: $gray;
		cursor: pointer;
		text-align: left;
		outline: none;

		&:hover, &:focus, &.active {
			color: $red;
		}
	}

	@include tablet {
		border-top: none;
		border-bottom: none;
		padding: 0;
		margin: 0;
	}
}

#filter {
	@include type-size-3;

	h4 {
		font-weight: 400;
		margin: 0;
	}

	summary {
		width: 100%;
		max-width: 180px;
		position: relative;
		list-style: none;
		color: $gray;
		cursor: pointer;
		transition: color 150ms ease-in-out;

		&:hover {
			color: $red;
		}

		&:focus {
			outline: 0;
		}

		&::-webkit-details-marker {
			display: none;
		}

		svg {
			user-select: none;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			fill: currentColor;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.current {
		color: $red;
	}

	details[open] + .current {
		display: none;
	}
}

#footer {
	nav {
		position: relative;
		display: grid;
		width: 100%;
		grid-gap: 20px;

		@include tablet {
			grid-template-columns: 155px 1fr;
		}

		@include desktop {
			grid-template-columns: 250px 1fr;
		}
	}
	
	img {
		max-width: 120px;
	}
}