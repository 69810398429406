@mixin type-size-1 {
	font-size: 36px;
    line-height: 50px;
	letter-spacing: -2px;

	@include tablet {
		font-size: 48px;
		line-height: 60px;
		letter-spacing: -2px;
	}
}

@mixin type-size-2 {
	font-size: 27px;
	line-height: 32px;
	letter-spacing: 0;

	@include tablet {
		font-size: 36px;
		line-height: 42px;
		letter-spacing: 0;
	}
}

@mixin type-size-3 {
	font-size: 18px;
	line-height: 26px;
	letter-spacing: 0;

	@include tablet {
		font-size: 24px;
		line-height: 32px;
		letter-spacing: 0;
	}
}

@mixin type-size-4 {
	font-size: 14.5px;
	line-height: 24px;
	letter-spacing: 0;

	@include tablet {
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0;
	}
}

@mixin type-size-5 {
	font-size: 9px;
	line-height: 12px;
	letter-spacing: 2px;

	@include tablet {
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 2px;
	}
}


::selection {
	background-color: $red;
	color: $white;
}

h1 {
	@include type-size-2;
	font-weight: 300;
}

h2 {
	@include type-size-2;
	font-weight: 300;
}

h3 {
	@include type-size-3;
	font-weight: 500;
}

a {
	text-decoration: none;
	color: $gray;
	transition: color 150ms ease-in-out;

	&:hover, &:focus {
		color: $red;
	}

	&.active {
		color: $red;
	}
}
