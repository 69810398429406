* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

html {
	height: 100%;
	scroll-behavior: smooth;
}

body {
	@include type-size-4;

	font-family: 'akzidenz-grotesk', sans-serif;
	padding: 20px;

	@include tablet {
		height: 100%;
		// Padding handled by size container
		padding-right: 0;
	}

	@include desktop {
		padding: 20px 55px;
		// Padding handled by size container
		padding-right: 0;
	}
}

nav > ul {
	list-style: none;
	padding: 0;
	margin: 0;
	color: #b3b3b3;
	font-size: 12px;
	line-height: 16px;
}

#navigation-container {
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	& {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
}

#root-container {
	height: 100%;

	main {
		// Make sure headings etc align with the nav
		#size-container > :first-child {
			margin-top: 0;
		}

		.shadow {
			position: sticky;
			left: 0;
			height: 50px;
			display: none;
			pointer-events: none;

			&:first-child {
				top: 0;
				background: linear-gradient(to bottom, #fff, rgba(255, 255, 255, 0));
				opacity: 0;
				transition: opacity 150ms ease-out;
				margin-bottom: -50px;
			}

			&:last-child {
				bottom: 0;
				background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
			}

			@include tablet {
				display: block;
			}
		}

		&.scrolled {
			.shadow {
				opacity: 1;
			}
		}
	}

	#footer {
		margin-top: 20px;
	}

	@include tablet {
		height: 100%;
		display: grid;
		grid-template-areas:
			'logo logo'
			'nav main'
			'footer footer';
		grid-template-columns: 155px 1fr;
		grid-template-rows: 100px 1fr 50px;
		grid-gap: 0 20px;

		#logo {
			grid-area: logo;
		}

		#navigation {
			grid-area: nav;
		}

		main {
			padding-right: 20px;
			grid-area: main;
			position: relative;
			overflow: auto;
			scroll-behavior: smooth;

			#size-container {
				max-width: 1300px;
			}
		}

		#footer {
			grid-area: footer;
			margin-top: 0;
		}
	}

	@include desktop {
		grid-template-columns: 250px 1fr;

		main {
			padding-right: 55px;
		}
	}
}
