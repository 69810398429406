$tablet: 800px;
$desktop: 1000px;
$wide: 1300px;
$full: 1500px;

@mixin tablet {
	@media screen and (min-width: $tablet), print {
		@content;
	}
}

@mixin desktop {
	@media screen and (min-width: $desktop), print {
		@content;
	}
}

@mixin wide {
	@media screen and (min-width: $wide), print {
		@content;
	}
}

@mixin full {
	@media screen and (min-width: $full), print {
		@content;
	}
}
